@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
	html {
		-webkit-tap-highlight-color: transparent;
	}
}

body {
	overflow-x: hidden;
}
